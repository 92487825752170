/* unplugin-vue-components disabled */import __unplugin_components_3 from 'D:/Projects/118_meijia/frontend/src/components/includes/NoData.vue';
import __unplugin_components_2 from 'D:/Projects/118_meijia/frontend/src/components/LoadingContent.vue';
import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/MJCalendarCustomListEvent.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MJCalendarCustomListStickyEvent.vue';
import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.sticky.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "mjcc-list"
};
var _hoisted_2 = {
  class: "mjcc-list__left"
};
var _hoisted_3 = {
  class: "mjcc-list__date"
};
var _hoisted_4 = {
  class: "mjcc-list__date-num"
};
var _hoisted_5 = {
  class: "mjcc-list__date-text"
};
var _hoisted_6 = {
  class: "mjcc-list__right"
};
var _hoisted_7 = {
  key: 0,
  class: "mjcc-list__nowtime"
};
import { EVENTS } from '@/js/test-data';
import moment from 'moment';
import { useEventGrouping } from '@/js/functions';
import { numberToZHWeekday } from '@/js/utils';
/**
 * 判斷今日
 * 判斷當前時間
 */

export default {
  __name: 'MJCalendarCustomList',
  emits: ['init'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var openEditEventPopup = inject('openEditEventPopup');
    var popperConfig = {
      placement: 'right-start',
      modifiers: [{
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 15
        }
      }, {
        name: 'flip'
      }, {
        name: 'offset',
        options: {
          offset: [-50, 0]
        }
      }, {
        name: 'arrow',
        options: {
          padding: 5 // 5px from the edges of the popper

        }
      }]
    };

    function padStart(num, pad) {
      return (num + "").padStart(2, "0");
    }

    var now = new Date();
    var todayStr = "".concat(now.getFullYear(), "/").concat(padStart(now.getMonth() + 1, 2), "/").concat(padStart(now.getDate(), 2));
    var nowTime = "".concat(padStart(now.getHours(), 2), ":").concat(padStart(now.getMinutes(), 2));
    now.setDate(now.getDate() + 1);
    var tomorrorStr = "".concat(now.getFullYear(), "/").concat(padStart(now.getMonth() + 1, 2), "/").concat(padStart(now.getDate(), 2));

    function isToday(string) {
      return moment(string).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
    }

    function viewItem(evt, eventObj) {
      openEditEventPopup(eventObj, {
        target: evt.currentTarget,
        editPopupConfig: {
          popperConfig: popperConfig,
          backdrop: true
        }
      });
    }

    function update() {}

    var sticky = inject('sticky');

    function onclickStickyItem(evt, stickyEvent) {
      sticky.show = true;
      sticky.addPopupTarget = evt.currentTarget;
      sticky.id = stickyEvent.id;
      sticky.addPopupConfig = {
        popperConfig: _objectSpread(_objectSpread({}, popperConfig), {}, {
          placement: 'left-start'
        }),
        backdrop: true
      };
    }

    var cData = reactive({
      ajaxDone: false,
      editEventPopupShow: false,
      editPopupTarget: '',
      events: computed(function () {
        return store.getters['calendar/dayEvents'].filter(function (evt) {
          // 移除助理跟鎖定
          if (evt.assistantId) return false;
          if (evt.lock) return false;
          return !evt.deletedAt;
        });
      }),
      activeEvent: {}
    });

    var _useEventGrouping = useEventGrouping(toRef(cData, 'events')),
        calendarEvents = _useEventGrouping.calendarEvents,
        eventsByDate = _useEventGrouping.eventsByDate; // 依本頁需要重新排序


    var eventsByDateArr = computed(function () {
      var sortedArr = Object.keys(eventsByDate.value).map(function (dateStr) {
        var date = moment(dateStr, 'YYYY-MM-DD');
        var needToContactEvents = eventsByDate.value[dateStr].needToContactEvents;
        var events = eventsByDate.value[dateStr].events.slice(); // 是否是今天

        var isToday = moment().format('YYYY-MM-DD') == date.format('YYYY-MM-DD'); // event要排序

        events = events.sort(function (a, b) {
          var atime = new Date();
          var btime = new Date();
          atime.setHours(a.startAt[0], a.startAt[1]);
          btime.setHours(b.startAt[0], b.startAt[1]);
          return atime.getTime() > btime.getTime() ? 1 : -1;
        }); // 塞入現在時間flag

        if (isToday) {
          // 找最後一個
          for (var idx = events.length - 1; idx >= 0; idx--) {
            var evt = events[idx];
            var evtTime = new Date();
            evtTime.setHours(evt.startAt[0], evt.startAt[1]);
            var nowTime = new Date();

            if (nowTime.getTime() > evtTime.getTime()) {
              // 前一個才是當前時間要顯示的位置
              events.splice(idx + 1, 0, {
                nowHourFlag: true
              });
              break;
            }

            if (idx == 0) {
              // 沒有預約比當前時間早
              events.unshift({
                nowHourFlag: true
              });
            }
          }
        }

        var allEvents = [].concat(needToContactEvents).concat(events);
        return {
          timestamp: date.isValid() ? date._d.getTime() : '',
          needToContactEvents: needToContactEvents,
          events: events,
          allEvents: allEvents
        };
      }).sort(function (a, b) {
        return a.timestamp > b.timestamp ? -1 : 1;
      });
      return sortedArr;
    });
    /**
     * 更新事件
     */

    function onEventUpdate(evt) {}

    function onEventRemove(evt) {}

    onMounted(function () {
      emit('init', {
        update: update,
        events: cData.events
      });
      cData.ajaxDone = true;
    });
    return function (_ctx, _cache) {
      var _component_MJCalendarCustomListStickyEvent = __unplugin_components_0;

      var _component_MJCalendarCustomListEvent = __unplugin_components_1;

      var _component_LoadingContent = __unplugin_components_2;

      var _component_NoData = __unplugin_components_3;

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_LoadingContent, {
        visible: _unref(cData).ajaxDone
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventsByDateArr), function (day) {
            return _openBlock(), _createElementBlock("div", {
              key: day.timestamp,
              class: _normalizeClass(["mjcc-list__day", {
                'is-today': isToday(day.timestamp)
              }])
            }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(day.timestamp ? _unref(moment)(day.timestamp).format('DD') : ''), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(day.timestamp ? _unref(moment)(day.timestamp).format('MM') : '') + "月(" + _toDisplayString(_unref(numberToZHWeekday)(_unref(moment)(day.timestamp)._d.getDay())) + ") ", 1)])]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.allEvents, function (event, idx) {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: event.id
              }, [event.nowHourFlag ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : event.sticky ? (_openBlock(), _createBlock(_component_MJCalendarCustomListStickyEvent, {
                key: 1,
                data: event,
                onClick: function onClick($event) {
                  return onclickStickyItem($event, event);
                }
              }, null, 8, ["data", "onClick"])) : (_openBlock(), _createBlock(_component_MJCalendarCustomListEvent, {
                key: 2,
                data: event,
                onClick: function onClick($event) {
                  return viewItem($event, event);
                }
              }, null, 8, ["data", "onClick"]))], 64);
            }), 128))])], 2);
          }), 128))];
        }),
        _: 1
      }, 8, ["visible"]), _createVNode(_component_NoData, {
        watch: "",
        "watch-data": _unref(eventsByDateArr)
      }, null, 8, ["watch-data"])]);
    };
  }
};